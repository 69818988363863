import React from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card
} from 'reactstrap'
import bannerImage from '../img/contact-bg.jpg'
import Banner from '../components/Banner'
import Layout from '../components/Layout'

class ContactUsPage extends React.Component {
  render() {
    return (
      <Layout>
        <Banner image={bannerImage} title="Contact Us" />
        <section className="bg-primary padded-section">
          <Container>
            <Card body style={{ color: 'black' }}>
              <Form name="contact" method="post" data-netlify="true">
                <input type="hidden" name="form-name" value="contact" />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        First Name<sup>*</sup>
                      </Label>
                      <Input type="text" name="first_name" required />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label>
                        Last Name<sup>*</sup>
                      </Label>
                      <Input type="text" name="last_name" required />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Organization</Label>
                      <Input type="text" name="organization" />
                    </FormGroup>
                  </Col>

                  <Col>
                    <div className="field">
                      <Label>
                        Email Address<sup>*</sup>
                      </Label>
                      <Input type="email" name="email" required />
                    </div>
                  </Col>
                </Row>

                <FormGroup>
                  <Label>Phone Number</Label>
                  <Input type="text" name="phone" />
                </FormGroup>

                <FormGroup>
                  <Label>
                    Message<sup>*</sup>
                  </Label>
                  <Input type="textarea" name="message" required />
                </FormGroup>

                <FormGroup style={{ textAlign: 'center' }}>
                  <Button color="primary" style={{ width: 200 }}>
                    Submit
                  </Button>
                </FormGroup>
              </Form>
            </Card>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default ContactUsPage
